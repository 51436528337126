let klickartPerformaticTimer = () => {
  var BASE_URL = document.querySelector("meta[name='klickart:url']").content;
  var PREVIEW_STAG_HOSTNAME = "art.kpages";
  var PREVIEW_PROD_HOSTNAME = "art.klickpages";
  var serverTimeRequestURL = BASE_URL + "/public/server/date_time";
  var timers = document.querySelectorAll("[data-klickart-timer-options]");
  var secondsPassed = 0;
  var tickEvent = new CustomEvent("timerTick", {
    detail: { secondsPassed },
  });
  if (timers) {
    setInterval(function tick() {
      tickEvent = new CustomEvent("timerTick", {
        detail: { secondsPassed },
      });
      document.body.dispatchEvent(tickEvent);
      secondsPassed += 1000;
    }, 1000);
  }
  function isPreviewPage() {
    var hostname = window.location.hostname;

    return (
      hostname.includes(PREVIEW_PROD_HOSTNAME) ||
      hostname.includes(PREVIEW_STAG_HOSTNAME)
    );
  }
  function httpGetAsync(theUrl, callback) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function onReady() {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
        callback(xmlHttp.responseText);
    };
    xmlHttp.open("GET", theUrl, true);
    xmlHttp.send(null);
  }
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookies = decodedCookie.split(";");
    for (var index = 0; index < cookies.length; index += 1) {
      var cookie = cookies[index];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  }
  function redirect(url) {
    window.location.href = url;
  }
  function getTimerDisplays(timer) {
    var days = timer.querySelector("[data-timer-replace='%D']");
    var hours = timer.querySelector("[data-timer-replace='%H']");
    var minutes = timer.querySelector("[data-timer-replace='%M']");
    var seconds = timer.querySelector("[data-timer-replace='%S']");
    return [days, hours, minutes, seconds];
  }
  function setLoaders(timerDisplays) {
    var displays = timerDisplays;
    for (var index = 0; index < displays.length; index += 1) {
      if (displays[index]) {
        displays[index].innerHTML = "&orarr;";
      }
    }
  }
  function updateDisplay(display, data, slice) {
    var currentDisplay = display;
    if (display.innerHTML !== data.slice(slice)) {
      currentDisplay.innerHTML = data.slice(slice);
    }
  }
  function setDisplays(timerDisplays, dateData) {
    var displays = timerDisplays;
    var data = dateData;
    if (displays[0]) {
      updateDisplay(displays[0], data[0].toString(), 0);
    }
    for (var index = 1; index < displays.length; index += 1) {
      if (displays[index]) {
        updateDisplay(displays[index], "0" + data[index], -2);
      }
    }
  }
  function getTimeRemaining(startDate, endDate) {
    var dateNow = new Date(startDate);
    return parseInt((endDate - dateNow.getTime()) / 1000);
  }
  function calculate(timeRemaining) {
    var remainingTime = timeRemaining;
    var days = parseInt(remainingTime / 86400);
    remainingTime %= 86400;
    var hours = parseInt(remainingTime / 3600);
    remainingTime %= 3600;
    var minutes = parseInt(remainingTime / 60);
    remainingTime %= 60;
    var seconds = parseInt(remainingTime, 10);
    return [days, hours, minutes, seconds];
  }
  function tickTimer(startDate, endDate, displays, redirectUrl) {
    var timeRemaining = getTimeRemaining(startDate, endDate);
    if (timeRemaining < 0) {
      if (redirectUrl) {
        redirect(redirectUrl);
      }
      setDisplays(displays, [0, 0, 0, 0]);
      return;
    }
    var data = calculate(timeRemaining);
    setDisplays(displays, data);
  }
  function countdown(options, timer) {
    var timerDisplays = getTimerDisplays(timer);
    setLoaders(timerDisplays);
    if (options.selectedType === "date") {
      var endDate = new Date(
        options.dateTime.toString() + options.timezone.toString()
      );
      endDate = endDate.getTime();
      if (Number.isNaN(endDate)) {
        throw new Error("invalid date provided on timer section");
      }
      return httpGetAsync(
        serverTimeRequestURL,
        function treatResponse(response) {
          var parsedRes = JSON.parse(response);
          var serverDate = new Date(parsedRes.date_time).getTime();
          document.body.addEventListener("timerTick", function timeTick(event) {
            tickTimer(
              serverDate + event.detail.secondsPassed,
              endDate,
              timerDisplays,
              options.url
            );
          });
        }
      );
    }
    var siteLoadedTime = new Date();
    var startTime = siteLoadedTime.getTime();
    var sectionId = timer.closest("section").parentElement.id;
    var endDate = getCookie("timer" + sectionId + "-endDate");
    if (!endDate) {
      endDate =
        options.hours * 3600000 +
        options.minutes * 60000 +
        options.seconds * 1000 +
        startTime;
      if (!isPreviewPage()) {
        document.cookie = "timer" + sectionId + "-endDate=" + endDate;
      }
    }
    return document.body.addEventListener(
      "timerTick",
      function timeTick(event) {
        tickTimer(
          startTime + event.detail.secondsPassed,
          endDate,
          timerDisplays,
          options.url
        );
      }
    );
  }
  if (timers) {
    for (var index = 0; index < timers.length; index += 1) {
      var options = JSON.parse(timers[index].dataset.klickartTimerOptions);
      countdown(options, timers[index]);
    }
  }
};

export { klickartPerformaticTimer }
